import {
  RiImageLine,
  RiUser6Line,
  RiSearch2Line,
  RiBuildingLine,
  RiAttachmentLine,
  RiAwardLine,
  RiBarChart2Line,
  RiBookmarkLine,
  RiBriefcase2Line,
  RiCalendar2Line,
  RiGlobalLine,
  RiMagicLine,
  RiLayout2Line,
  RiTv2Line,
  RiNewspaperLine,
  RiBookLine,
  RiBookOpenLine,
  RiFlaskLine,
  RiFoldersLine,
  RiHeart2Line,
  RiLock2Line,
  RiMap2Line,
  RiMapPin2Line,
  RiMovieLine,
  RiShieldStarLine,
  RiStarLine,
  RiTeamLine,
} from "react-icons/ri"



// DASHBOARD TABS
export const ATTRIBUTE = "attribute"
export const AUTOMATION_FLOW = "automation_flow"
export const CONTENT = "content"
export const GROUP = "group"
export const IMPORT = "import"
export const IMPORT_CONTENT = "import_content"
export const PARTNER = "partner"
export const PROJECT = "project"
export const PROJECT_JOB = "project_job"
export const ROLE = "role"
export const STRUCTURE = "structure"
export const USER = "user"

// METRICS
export const MAX_USER_SELECTION = 100
export const MAX_PAGINATION_DASHBOARD = 10
export const MAX_PAGINATION_DASHBOARD_EXTENDED = 100

// MENU
export const MEMBERS_MENU = "MEMBERS_MENU"
export const PROJECTS_MENU = "PROJECTS_MENU"
export const PROJECT_JOBS_MENU = "PROJECT_JOBS_MENU"
export const SETTINGS_MENU = "SETTINGS_MENU"
export const ROLES_MENU = "ROLES_MENU"

// ICONS PANEL
export const ICONS_PANEL = {
  RiImageLine,
  RiUser6Line,
  RiSearch2Line,
  RiBuildingLine,
  RiAttachmentLine,
  RiAwardLine,
  RiBarChart2Line,
  RiBookmarkLine,
  RiBriefcase2Line,
  RiCalendar2Line,
  RiGlobalLine,
  RiMagicLine,
  RiLayout2Line,
  RiTv2Line,
  RiNewspaperLine,
  RiBookLine,
  RiBookOpenLine,
  RiFoldersLine,
  RiHeart2Line,
  RiFlaskLine,
  RiMapPin2Line,
  RiMap2Line,
  RiMovieLine,
  RiStarLine,
  RiShieldStarLine,
  RiLock2Line,
  RiTeamLine
}