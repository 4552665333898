import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
// Components
import Loader from "../elements/loader/PageContentLoader"
import ContentIsEmpty from "../elements/divContainer/ContentIsEmpty"
// Utils
import moment from 'moment'
import {RiMore2Fill, RiRobot2Line} from "react-icons/ri";
import JobPanel from "./JobPanel";
import {getFormatedDate} from "../../utils/date";


const TableProjectJobs = ({ data, dataLoaded, getMoreInfo, getTypeTranslation, getTradKeyStatus }) => {
  
  // Translations
  const { t } = useTranslation(['common', 'dashboard'])
  const [panelIsOpen, setPanelIsOpen] = useState(false)
  const [selectedJob, setSelectedJob] = useState(null);
  

  const statusClassName = (status) => {
    let classes = ''

    switch(status?.toLowerCase()) {
      case 'request':
      case 'requested':
        return classes + ' bg-gray-100 border-gray-300 text-gray-600'
      case 'in_progress':
      case 'working':
        return classes + ' bg-yellow-100 border-yellow-300 text-yellow-700'
      case 'success':
      case 'complete':
        return classes + ' bg-green-100 border-green-300 text-green-600'
      case 'failed':
      case 'failure':
        return classes + ' bg-red-100 border-red-300 text-red-600'
      default:
        return classes
    }
  }

  const sourceTypeClassName = (source) => {
    let classes = ''

    switch (source){
      case 'automation':
        return classes + ' bg-gray-100 border-gray-300 text-gray-600'
      default:
        return classes + ' bg-blue-100 border-blue-300 text-blue-600'
    }
  }

  useEffect(() => {
    if(selectedJob){
      setPanelIsOpen(true)
    }
  }, [selectedJob])

  return (
    <>
      <JobPanel
        open={panelIsOpen}
        setOpen={setPanelIsOpen}
        job={selectedJob}
      />

      <div className="flex flex-col overflow-auto pr-2.5 relative memoryScrollbar">
        {/* GRID HEAD */}
        <div className={`hidden min850:grid grid-cols-6imports w-full text-sm font-bold bg-gray-200 sticky top-0`}>
          <span className="p-2">{t('Id')}</span>
          <span className="p-2">{t('Entity')}</span>
          <span className="p-2">{t('Content')}</span>
          <span className="p-2">{t('date.Creation-date')}</span>
          <span className="p-2">{t('Creator')}</span>
          <span className="p-2">{t('status.Status', {ns: 'common'})}</span>
        </div>

        {/* GRID BODY */}
        <div className="flex flex-col">

          {dataLoaded ?
            <>
              {data.length > 0 ?
                data.map((_job, index) => (
                  <div key={`row-import-${index}`} className={`grid w-full p-4 mb-4 rounded text-sm bg-gray-100 shadow-bottom min850:grid grid-cols-1 min850:grid-cols-6imports min850:border-b min850:bg-transparent min850:shadow-none min850:p-0 min850:mb-0 min850:rounded-none min850:hover:bg-gray-100`}>
                    <span className="dashboard__gridItem" title={_job.id} data-label={`${t('Id')} :`}>{_job.external_id}</span>
                    <span className="dashboard__gridItem" title={_job.project_id} data-label={`${t('Entity')} :`}>{_job.project_id}</span>
                    <span className="dashboard__gridItem" title={getTypeTranslation(_job.type)} data-label={`${t('contenu')} :`}>{_job?.config?.content_title}</span>
                    <span className="dashboard__gridItem" title={getFormatedDate(_job.createdAt)} data-label={`${t('Date')} :`}>
                      {getFormatedDate(_job.createdAt)}
                    </span>
                    <span
                      className="dashboard__gridItem"
                      title={_job.creator?.first_name ? `${_job.creator?.first_name} ${_job.creator?.last_name}` : ''}
                      data-label={`${t('Creator')} :`}
                    >
                      {_job.creator?.first_name &&
                        <>
                          <span className={`max-w-full cursor-pointer inline-flex gap-1 border text-xs font-semibold inline rounded-full p-0.5 px-2 ${sourceTypeClassName(_job.source)}`}>
                            <span className={`truncate w-full`}>
                              {`${_job.creator?.first_name} ${_job.creator?.last_name}`}
                            </span>
                          </span>
                        </>
                      }
                    </span>
                    <span className="dashboard__gridItem" title={getTradKeyStatus(_job.status)} data-label={`${t('status.Status')} :`}>
                      <span className={`flex gap-x-4 pr-2 justify-between`}>
                        <span className={`cursor-pointer inline-flex gap-1 border text-xs font-semibold inline rounded-full p-0.5 px-2 ${statusClassName(_job.status)}`}>
                          {getTradKeyStatus(_job.status)}
                        </span>
                        <span
                          className={`cursor-pointer`}
                          onClick={(e) => setSelectedJob(_job)}
                        >
                          <RiMore2Fill className={`w-4 h-4`} />
                        </span>
                      </span>
                    </span>
                  </div>
                  ))
              :
                <ContentIsEmpty>
                    {t('No-element')}
                </ContentIsEmpty>
              }
            </>
          :
            <Loader className="h-48" />
          }
        </div>
      </div>
    </>
  )
}

export default TableProjectJobs