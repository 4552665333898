import { useState } from 'react'
// Utils
import {
  ROLE,
  GROUP,
  PARTNER,
  IMPORT,
  ATTRIBUTE,
  STRUCTURE,
  IMPORT_CONTENT,
  CONTENT,
  AUTOMATION_FLOW, PROJECT, PROJECT_JOB
} from '../utils/constantsBackOffice'
import { makeCaseAndAccentInsensitiveString } from '../utils/stringUtils'
import {getFormatedDate} from "../utils/date";


const useSearchItemsProcess = () =>  {

  const [searchingList, setSearchingList] = useState(null)

  const makeItemSearch = (term, data, entity) => {
    let newSearchingList = data.filter(item => {
      let normalizedID;
      let normalizedName;

      switch (entity){
        case ROLE:
        case GROUP:
        case PARTNER:
        case STRUCTURE:
        case ATTRIBUTE:
          let normalizedGroupName = makeCaseAndAccentInsensitiveString(item.name)
          return normalizedGroupName.includes(term)
  
        case AUTOMATION_FLOW:
          normalizedID = makeCaseAndAccentInsensitiveString(item.id)
          normalizedName = makeCaseAndAccentInsensitiveString(item.name)
          return normalizedID.includes(term)
            || normalizedName.includes(term)

        case IMPORT:
          normalizedID = makeCaseAndAccentInsensitiveString(item.id)
          let normalizedImportDetail = makeCaseAndAccentInsensitiveString(item.translated_detail)
          let normalizedImportType = makeCaseAndAccentInsensitiveString(item.translated_type)
          let normalizedImportStatus = makeCaseAndAccentInsensitiveString(item.translated_status)
          let normalizedCreator = makeCaseAndAccentInsensitiveString(`${item.creator.first_name} ${item.creator.last_name}`)
          return normalizedID.includes(term)
            || normalizedCreator.includes(term)
            || normalizedImportDetail.includes(term)
            || normalizedImportType.includes(term)
            || normalizedImportStatus.includes(term)

        case IMPORT_CONTENT:
          normalizedID = makeCaseAndAccentInsensitiveString(item.import_id)
          let normalizedImportContentModel = makeCaseAndAccentInsensitiveString(item.model_name)
          let normalizedImportContentTitle = makeCaseAndAccentInsensitiveString(item.value?.title)
          let normalizedImportContentCreator = makeCaseAndAccentInsensitiveString(`${item.creator_data.first_name} ${item.creator_data.last_name}`)
          let normalizedImportContentMedias = makeCaseAndAccentInsensitiveString(item.medias_status_text)
          return normalizedID.includes(term)
            || normalizedImportContentModel.includes(term)
            || normalizedImportContentTitle.includes(term)
            || normalizedImportContentCreator.includes(term)
            || normalizedImportContentMedias.includes(term)

        case CONTENT:
          let normalizedContentModel = makeCaseAndAccentInsensitiveString(item.model_name)
          let normalizedContentTitle = makeCaseAndAccentInsensitiveString(item.value?.title)
          let normalizedContentCreator = makeCaseAndAccentInsensitiveString(`${item.creator_data.first_name} ${item.creator_data.last_name}`)
          let normalizedContentDescription = makeCaseAndAccentInsensitiveString(item.value?.description)
          return normalizedContentModel.includes(term)
            || normalizedContentTitle.includes(term)
            || normalizedContentCreator.includes(term)
            || normalizedContentDescription.includes(term)

        case PROJECT:
          let normalizedProjectId = makeCaseAndAccentInsensitiveString(item.id)
          let normalizedProjectName = makeCaseAndAccentInsensitiveString(item.name)
          return normalizedProjectId.includes(term)
            || normalizedProjectName.includes(term)

        case PROJECT_JOB:
          let normalizedProjectJobId = makeCaseAndAccentInsensitiveString(item.external_id);
          let normalizedProjectJobEntity = makeCaseAndAccentInsensitiveString(item.project_id);
          let normalizedProjectJobContent = makeCaseAndAccentInsensitiveString(item?.config?.content_title);
          let normalizedProjectJobDate = makeCaseAndAccentInsensitiveString(getFormatedDate(item.createdAt));
          let normalizedProjectJobFullName = `${makeCaseAndAccentInsensitiveString(item.creator?.first_name)} ${makeCaseAndAccentInsensitiveString(item.creator?.last_name)}`;
          let normalizedProjectJobStatus = makeCaseAndAccentInsensitiveString(item.translated_status);
          return normalizedProjectJobId.includes(term)
            || normalizedProjectJobEntity.includes(term)
            || normalizedProjectJobContent.includes(term)
            || normalizedProjectJobFullName.includes(term)
            || normalizedProjectJobDate.includes(term)
            || normalizedProjectJobStatus.includes(term)

        default:
          let normalizedFirstName = makeCaseAndAccentInsensitiveString(item.first_name)
          let normalizedLastName = makeCaseAndAccentInsensitiveString(item.last_name)
          let normalizedEmail = makeCaseAndAccentInsensitiveString(item.email)
          return normalizedFirstName.includes(term) || normalizedLastName.includes(term) || normalizedEmail.includes(term)
      }
    })

    setSearchingList(newSearchingList)
  }
  

  return [ searchingList, setSearchingList, makeItemSearch ]
}

export default useSearchItemsProcess
